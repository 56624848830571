import { tr } from "date-fns/locale";

export const isDate = (obj: any) => {
  return obj instanceof Date && Object.prototype.toString.call(obj) === "[object Date]";
};

export const getDateStringMMDDYYYY = (date: Date | string | undefined): string => {
  if (!date) {
    return "";
  }
  if (typeof date === "string") {
    try {
      date = new Date(date);
    } catch (e) {
      return getDateStringMMDDYYYY(new Date());
    }
  }
  if (!isDate(date)) {
    return getDateStringMMDDYYYY(new Date());
  }
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const getDateFromMongoDBObjectId = (objectId: string | any): Date => {
  const timestamp = objectId.toString().substring(0, 8);
  return new Date(parseInt(timestamp, 16) * 1000);
};

export const getTimestampSeconds = (): number => {
  return Math.floor(new Date().getTime() / 1000);
};

export const isRecent = (timestamp: number, interval = 1000 * 60 * 30) => {
  const timeDiff = Date.now() - timestamp;
  return timeDiff < interval;
};

//timestamp is in past 30 minutes
export const isRecentSeconds = (timestamp: number, interval = 60 * 30) => {
  return isRecent(timestamp * 1000, interval * 1000);
};

export function getApproxTimeFromPrimeMeridian(long: number | string, roundToNearestHour: boolean = false): Date {
  let longNum: number | null = null;

  //convert a lat,long string like "44,-77" to a -77 as a num.
  if (typeof long === "string") {
    if (long.includes(",")) {
      //split the string into two numbers
      const [lat, longTemp] = long.split(",");
      longNum = parseFloat(longTemp);
    } else {
      longNum = Number(long);
    }
  } else if (typeof long === "number") {
    longNum = long;
  }

  if (typeof longNum !== "number") return new Date();

  // Get current London time
  const londonTime = new Date();

  // Calculate hours offset from longitude
  // Earth rotates 360° in 24 hours, so 15° = 1 hour
  let hoursOffset = longNum / 15;

  // Round to nearest hour if requested
  if (roundToNearestHour) {
    hoursOffset = Math.round(hoursOffset);
  }

  // Add the offset in milliseconds to London time
  const offsetMilliseconds = hoursOffset * 60 * 60 * 1000;
  const approximateTime = new Date(londonTime.getTime() + offsetMilliseconds);

  return approximateTime;
}

// Add validation helper function
export const isValidDate = (date: Date | undefined): boolean => {
  return date instanceof Date && !isNaN(date.getTime());
};

//for using at the end of a statment like "your authorization to complete the next {N} actions ____"
export function dateToFriendlyTimeFromNow(date: Date): string {
  const now = new Date();
  const diffMs = date.getTime() - now.getTime();
  const diffMinutes = Math.ceil(diffMs / (1000 * 60));
  const diffHours = Math.ceil(diffMs / (1000 * 60 * 60));
  const diffDays = Math.ceil(diffMs / (1000 * 60 * 60 * 24));

  // Under 1 hour
  if (diffMinutes < 60) {
    return `within ${diffMinutes} minutes`;
  }

  // Under 1 day
  if (diffHours < 24) {
    return `within ${diffHours} hours`;
  }

  // Under 1 week
  if (diffDays < 7) {
    return `within ${diffDays} days`;
  }

  // Format the date
  const month = date.toLocaleString("en-US", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();
  const currentYear = now.getFullYear();

  // Add appropriate suffix to day number
  const dayWithSuffix = day + (day % 10 === 1 && day !== 11 ? "st" : day % 10 === 2 && day !== 12 ? "nd" : day % 10 === 3 && day !== 13 ? "rd" : "th");

  // Under a year - return "by Month Day"
  if (year === currentYear) {
    return `by ${month} ${dayWithSuffix}`;
  }

  // Over a year - include the year
  return `by ${month} ${dayWithSuffix}, ${year}`;
}

export function dateToFriendlyString(date?: Date): string {
  // Use the provided date or default to "now"
  const d = date ?? new Date();

  // Extract short timezone name by using toLocaleTimeString
  // and splitting off the last "word"
  const shortTimeZone =
    d
      .toLocaleTimeString("en-US", {
        timeZoneName: "short",
        hour12: true,
      })
      .split(" ")
      .pop() ?? "";

  // Convert hours/minutes to a 12-hour clock
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const isPM = hours >= 12;
  const hour12 = hours % 12 || 12; // 0 => 12
  const mm = minutes < 10 ? `0${minutes}` : minutes;
  const ampm = isPM ? "PM" : "AM";

  // If no date was passed in, we say "Today"
  // Otherwise we use the day of the week, e.g. "Thursday"
  if (!date) {
    // Format: "Today, 3:45 PM PDT"
    return `Today, ${hour12}:${mm} ${ampm} ${shortTimeZone}`;
  } else {
    // Get day name (long form)
    const weekday = d.toLocaleString("en-US", { weekday: "long" });
    // Format: "Thursday, 3:45 PM PDT"
    return `${weekday}, ${hour12}:${mm} ${ampm} ${shortTimeZone}`;
  }
}
