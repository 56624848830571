import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import { set } from "lodash";
import { ModalSizeVariantProps } from "@/components/modals/ModalLayout";
import { BaseModalProps } from "@/components/modals/BaseModal";
import { WritableDraft } from "immer";

// Define the structure of each banner message
export interface BannerMessage {
  title: string;
  message: string;
  action?: () => void;
  buttonTitle?: string;
  buttonAction?: () => void;
  buttonUrl?: string;
  dismissible?: boolean;
}

export interface ModalMessage {
  title: string;
  desc?: string;
  jsx?: JSX.Element;
  confirmText?: string;
  dismissText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
  onCancel?: () => void;
  modalProps?: BaseModalProps;
  className?: string;
  showDismissButton?: boolean;
  onCloseOnDismiss?: boolean;
  dismissRetainsModal?: boolean;
  openOnAdd?: boolean;
}

// Define types the state
export type UIState = {
  bannerOpen: boolean;
  bannerMessages: BannerMessage[];
  modalOpen: boolean;
  modalMessages: ModalMessage[];
  closedBannerTitles: string[];
  sideMenuOpen: boolean;
  errorMsg: string;
  newlyInvitedUser: Record<string, any>;
  notificationPriority: number;
  notificationSheetOpen: boolean;
  isLoadedFormPage: boolean;
  navbarBottomY: number;
};

const uISlice = createSlice({
  name: "UI", // Name of the slice
  initialState: {
    bannerOpen: false,
    bannerMessages: [] as BannerMessage[],
    closedBannerTitles: [] as string[],
    modalOpen: false,
    modalMessages: [] as ModalMessage[],
    sideMenuOpen: false,
    navbarBottomY: 0,
    errorMsg: "",
    newlyInvitedUser: {},
    notificationPriority: 1,
    notificationSheetOpen: false,
    isLoadedFormPage: false,
  },

  reducers: {
    // This is an example of [type] syntax in a reducer function
    setBannerOpen: (state, action) => {
      state.bannerOpen = action.payload;
    },
    addOrUpdateBannerMessage: (state, action: PayloadAction<BannerMessage>) => {
      const { title, message, dismissible = true, ...rest } = action.payload;

      // Check if a message with this title already exists
      const existingMessageIndex = state.bannerMessages.findIndex((msg) => msg.title === title);

      if (existingMessageIndex !== -1) {
        // Message exists, update it
        state.bannerMessages[existingMessageIndex].message = message;
      } else {
        // New message, add it
        state.bannerMessages.push({ title, message, dismissible, ...rest });
      }
    },
    setBannerMessages: (state, action) => {
      state.bannerMessages = action.payload;
    },
    setClosedBannerTitles: (state, action) => {
      state.closedBannerTitles = action.payload;
    },
    setModalOpen: (state, action) => {
      state.modalOpen = action.payload;
    },
    addOrUpdateModalMessage: (state, action: PayloadAction<ModalMessage>) => {
      const obj = { ...action.payload };
      const title = obj.title;
      const desc = obj.desc;

      // Check if a message with this title already exists
      const existingMessageIndex = state.modalMessages.findIndex((msg) => msg.title === title);

      if (existingMessageIndex !== -1) {
        state.modalMessages[existingMessageIndex] = obj as WritableDraft<ModalMessage>;
      } else {
        state.modalMessages.push(obj as WritableDraft<ModalMessage>);
      }
      if (action.payload.openOnAdd) {
        state.modalOpen = true;
      }
    },
    removeModalMessage: (state, action: PayloadAction<string>) => {
      //remove a modal message by title
      state.modalMessages = state.modalMessages.filter((msg) => msg.title !== action.payload);
    },
    setModalMessages: (state, action) => {
      state.modalMessages = action.payload;
    },
    addModalMessage: (state, action: { payload: ModalMessage }) => {
      //check if modal with title and desc already exists
      const { title, desc } = action.payload;
      const existingModalIndex = state.modalMessages.findIndex((msg) => msg.title === title && msg.desc === desc);
      if (existingModalIndex !== -1) {
        //update modal
        state.modalMessages[existingModalIndex] = { ...action.payload } as WritableDraft<ModalMessage>;
      } else {
        state.modalMessages.push({ ...action.payload } as WritableDraft<ModalMessage>);
      }
      if (action.payload.openOnAdd) {
        state.modalOpen = true;
      }
    },
    setSideMenuOpen: (state, action) => {
      state.sideMenuOpen = action.payload;
    },
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload;
    },
    setNewlyInvitedUser: (state, action) => {
      state.newlyInvitedUser = action.payload;
    },
    setNotificationPriority: (state, action) => {
      state.notificationPriority = action.payload;
    },
    setNotificationSheetOpen: (state, action) => {
      state.notificationSheetOpen = action.payload;
    },
    setIsLoadedFormPage: (state, action) => {
      state.isLoadedFormPage = action.payload;
    },
    setNavbarBottomY: (state, action) => {
      state.navbarBottomY = action.payload;
    },
    resetUISlice: (state) => {
      state.bannerOpen = false;
      state.bannerMessages = [];
      state.closedBannerTitles = [];
      state.modalOpen = false;
      state.modalMessages = [];
      state.sideMenuOpen = false;
      state.errorMsg = "";
      state.newlyInvitedUser = {};
      state.notificationPriority = 1;
      state.notificationSheetOpen = false;
      state.isLoadedFormPage = false;
    },
  },
});

export const {
  setSideMenuOpen,
  setErrorMsg,
  setNewlyInvitedUser,
  setBannerOpen,
  setBannerMessages,
  setClosedBannerTitles,
  addOrUpdateBannerMessage,
  setModalOpen,
  setModalMessages,
  addModalMessage,
  addOrUpdateModalMessage,
  removeModalMessage,
  setNotificationPriority,
  setNotificationSheetOpen,
  setIsLoadedFormPage,
  setNavbarBottomY,
  resetUISlice,
} = uISlice.actions;
export default uISlice.reducer;
