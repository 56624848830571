import { isDevEnv } from "./environments";

export const userFacingErrorMsg = (err: any, fallbackMsg?: string, formatErrorMsg: boolean = true): string => {
  if (isDevEnv()) console.error("userFacingErrorMsg", err);
  let message = "";
  if (typeof err === "string") {
    message = err;
  } else {
    if (err.response?.data?.message) {
      message = err.response.data.message;
    } else if (err.message) {
      message = err.message;
    }
  }

  if (!message) {
    message = fallbackMsg ?? "We encountered an error. If this persists, please contact support.";
  }
  if (formatErrorMsg) {
    message = formatUserErrorMsg(message);
  }

  return message;
};

export const formatUserErrorMsg = (msg: string, maxLength: number = 80): string => {
  if (!msg) return "";

  // trim the message to the max length
  msg = msg.length > maxLength ? msg.substring(0, maxLength) + "..." : msg;

  // Remove the "Error: " prefix from the error message
  msg = msg.replace(/(Error: |Error:)/g, "");

  // Remove any trailing periods
  msg = msg.replace(/\.$/, "");

  // Capitalize the first letter
  msg = msg.charAt(0).toUpperCase() + msg.slice(1);

  return msg;
};
