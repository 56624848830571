import(/* webpackMode: "eager" */ "/Users/erikkoning/Documents/Work/RefDocs/refdocs-app-sst/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/Users/erikkoning/Documents/Work/RefDocs/refdocs-app-sst/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Aleo-Regular.ttf\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/Aleo-Bold.ttf\",\"weight\":\"700\"}],\"variable\":\"--font-aleo\"}],\"variableName\":\"aleo\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/erikkoning/Documents/Work/RefDocs/refdocs-app-sst/src/components/ui/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/Users/erikkoning/Documents/Work/RefDocs/refdocs-app-sst/src/components/ui/toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/erikkoning/Documents/Work/RefDocs/refdocs-app-sst/src/components/ui/TopLevelUseClient.tsx");
;
import(/* webpackMode: "eager" */ "/Users/erikkoning/Documents/Work/RefDocs/refdocs-app-sst/src/styles/globals.css");
