"use client";

import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import NextLink, { type LinkProps as NextLinkProps } from "next/link";
import { useRouter } from "next/navigation";
import { forwardRef, useEffect, useRef, useState } from "react";

type PrefetchImage = {
  srcset: string;
  sizes: string;
  src: string;
  alt: string;
  loading: string;
};

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
/*
Prefetching taken from this repo:
https://github.com/ethanniser/NextFaster/blob/main/src/components/ui/link.tsx
*/

async function prefetchImages(href: string) {
  //disable
  return [];
  if (!href.startsWith("/") || href.startsWith("/order") || href === "/") {
    return [];
  }
  const url = new URL(href, window.location.href);
  const imageResponse = await fetch(`/api/prefetch-images${url.pathname}`, {
    priority: "low",
  });
  // only throw in dev
  if (!imageResponse.ok && process.env.NODE_ENV === "development") {
    throw new Error("Failed to prefetch images");
  }
  const { images } = (await imageResponse.json()) as { images: PrefetchImage[] };
  return images as PrefetchImage[];
}

const seen = new Set<string>();

const linkVariants = cva("", {
  variants: {
    variant: {
      blueLink: "appearance-none whitespace-nowrap text-skyBlue underline transition-all hover:text-darkPurple",
      blank: "",
    },
  },
  defaultVariants: {
    variant: "blank",
  },
});

type BaseLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof NextLinkProps> &
  NextLinkProps &
  VariantProps<typeof linkVariants> & {
    children?: React.ReactNode;
    className?: string;
  };

export type LinkProps = BaseLinkProps; // Remove the function signature

export const Link = forwardRef<HTMLAnchorElement, BaseLinkProps>(({ children, className, variant, ...props }, ref) => {
  const [images, setImages] = useState<PrefetchImage[]>([]);
  const [preloading, setPreloading] = useState<(() => void)[]>([]);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const router = useRouter();
  let prefetchTimeout: NodeJS.Timeout | null = null;

  useEffect(() => {
    if (props.prefetch === false) {
      return;
    }

    const linkElement = linkRef.current;
    if (!linkElement) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          // Set a timeout to trigger prefetch after 1 second
          prefetchTimeout = setTimeout(async () => {
            router.prefetch(String(props.href));
            await sleep(0); // We want the doc prefetches to happen first.
            void prefetchImages(String(props.href)).then((images) => {
              setImages(images);
            }, console.error);
            // Stop observing once images are prefetched
            observer.unobserve(entry.target);
          }, 300); // 300ms delay
        } else if (prefetchTimeout) {
          // If the element leaves the viewport before 1 second, cancel the prefetch
          clearTimeout(prefetchTimeout);
          prefetchTimeout = null;
        }
      },
      { rootMargin: "0px", threshold: 0.1 } // Trigger when at least 10% is visible
    );

    observer.observe(linkElement);

    return () => {
      observer.disconnect(); // Cleanup the observer when the component unmounts
      if (prefetchTimeout) {
        clearTimeout(prefetchTimeout); // Clear any pending timeouts when component unmounts
      }
    };
  }, [props.href, props.prefetch]);

  return (
    <NextLink
      ref={linkRef}
      prefetch={false}
      onMouseEnter={() => {
        router.prefetch(String(props.href));
        if (preloading.length) return;
        const p: (() => void)[] = [];
        for (const image of images) {
          const remove = prefetchImage(image);
          if (remove) p.push(remove);
        }
        setPreloading(p);
      }}
      onMouseLeave={() => {
        for (const remove of preloading) {
          remove();
        }
        setPreloading([]);
      }}
      className={cn(linkVariants({ variant }), className)}
      //style={{ textCol, transition: "all" }}
      onMouseDown={(e) => {
        const url = new URL(String(props.href), window.location.href);
        if (url.origin === window.location.origin && e.button === 0 && !e.altKey && !e.ctrlKey && !e.metaKey && !e.shiftKey) {
          e.preventDefault();
          router.push(String(props.href));
        }
      }}
      {...props}
    >
      {children}
    </NextLink>
  );
});

function prefetchImage(image: PrefetchImage) {
  if (image.loading === "lazy" || seen.has(image.srcset)) {
    return;
  }
  const img = new Image();
  img.decoding = "async";
  img.fetchPriority = "low";
  img.sizes = image.sizes;
  seen.add(image.srcset);
  img.srcset = image.srcset;
  img.src = image.src;
  img.alt = image.alt;
  let done = false;
  img.onload = img.onerror = () => {
    done = true;
  };
  return () => {
    if (done) return;
    img.src = img.srcset = "";
    seen.delete(image.srcset);
  };
}

Link.displayName = "Link"; // Add this for better debugging
