"use client";
// Providers are in use client becuase -> https://nextjs.org/docs/getting-started/react-essentials#context

import { SessionProvider } from "next-auth/react";
import { ThemeProvider, useTheme } from "next-themes";
import type { FC, ReactNode } from "react";
import ReduxProvider from "@/reduxState/reduxProvider";
import { ReactQueryProvider } from "@/utils/react-query-provider";
import { RouteChangeConfirmationProvider } from "@/utils/RouteChangeConfirmationProvider";
import { GlobalErrorBoundary } from "../errors/ErrorBoundary";
import { SafeUserWithOffice } from "@/utils/getCurrentUser";
import { CurrentUserProvider } from "@/utils/currentUserProvider";
import { useLastInteraction } from "@/contexts/LastInteractionContext";
//import { ThemeProvider as PrimerThemeProvider, BaseStyles as PrimerBaseStyles } from "@primer/react";
//			<PrimerThemeProvider>
//				<PrimerBaseStyles>

interface ProvidersProps {
  children: ReactNode;
  currentUser?: SafeUserWithOffice;
}
//Providers typically use react context to provide data to their children.
//https://react.dev/learn/passing-data-deeply-with-context
const Providers: FC<ProvidersProps> = ({ children, currentUser }) => {
  const { setTheme, theme } = useTheme();
  const { getLastInteraction } = useLastInteraction();

  return (
    <CurrentUserProvider initialUser={currentUser}>
      <ThemeProvider attribute="class" defaultTheme="light" forcedTheme={theme}>
        <ReduxProvider>
          <GlobalErrorBoundary getLastInteraction={getLastInteraction} initialUser={currentUser}>
            <ReactQueryProvider>
              {/*<RouteChangeConfirmationProvider>*/}
              <SessionProvider>{children}</SessionProvider>
            </ReactQueryProvider>
            {/*</RouteChangeConfirmationProvider>*/}
          </GlobalErrorBoundary>
        </ReduxProvider>
      </ThemeProvider>
    </CurrentUserProvider>
  );
};

export default Providers;
