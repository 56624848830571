"use client";

import React from "react";
import { ModalLayout } from "../modals/ModalLayout";
import { PageContentContainer } from "../ui/PageContentContainer";
import { Button } from "../ui/Button";
import axios from "axios";
import { ErrorToObj, removeCircularReferences } from "@/utils/objectManipulation";
import { objectToTableRows } from "@/utils/email";
import { debug } from "console";
import { useCurrentUser } from "@/utils/currentUserProvider";
import { SafeUserWithOffice } from "@/utils/getCurrentUser";
import { LastInteraction } from "../ui/TopLevelUseClient";
import { isDevEnv, isProdEnv } from "@/utils/environments";

interface ErrorBoundaryProps {
  children?: React.ReactNode;
  initialUser?: SafeUserWithOffice;
  getLastInteraction?: () => LastInteraction | null;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  nonReactError: Error | null;
  currentUser: SafeUserWithOffice | null;
}

export const handleClientSideError = async (
  error: Record<string, any> | string,
  errorInfo?: React.ErrorInfo,
  isReactError?: boolean,
  currentUser?: SafeUserWithOffice | null
): Promise<void> => {
  const errorData = {
    userEmail: currentUser?.email,
    ...(typeof error === "object" ? error : { error: error }),
    componentStack: errorInfo?.componentStack,
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    url: window.location.href,
    pathname: window.location.pathname,
    time: new Date().toISOString(),
    //user: userData.userData ?? null,
    isOnline: navigator.onLine,
    // Possibly other state, context, or environment details
    isReactError: isReactError,
  };

  let noCircularReferenceData;
  let htmlTableRows;
  try {
    htmlTableRows = objectToTableRows(errorData);
  } catch (e) {
    noCircularReferenceData = removeCircularReferences(errorData);
    htmlTableRows = objectToTableRows(noCircularReferenceData);
  }

  console.error("ErrorBoundary htmlTableRows", htmlTableRows);

  // No need to report errors in dev env
  if (isDevEnv()) return;

  //send via email
  await axios.post("/api/clientSideError", { data: htmlTableRows, note: "From handleClientSideError" });
};

export class GlobalErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static instance: GlobalErrorBoundary | null = null;
  //param to show if the error is a react error or not

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, nonReactError: null, currentUser: props.initialUser ?? null };
    GlobalErrorBoundary.instance = this;
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error, nonReactError: null, currentUser: null };
  }

  // A static method to manually set a non-React error
  static setNonReactError(error: Error) {
    if (GlobalErrorBoundary.instance) {
      GlobalErrorBoundary.instance.setState({
        nonReactError: error,
      });
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // caught an error in the child component
    if (isDevEnv()) return;

    handleClientSideError(
      { ...{ error: { ...ErrorToObj(error) } }, lastInteraction: this.props.getLastInteraction ? this.props.getLastInteraction() : {} },
      errorInfo,
      true,
      this.state.currentUser
    );
  }

  // Your reset method: This clears the error state.
  resetError = () => {
    this.setState({ hasError: false, error: null, nonReactError: null });
  };

  render(): React.ReactNode {
    if (this.state.hasError || this.state.nonReactError) {
      return (
        <PageContentContainer variant={"centeredXY"}>
          <ModalLayout title="An unexpected error occurred" variant="error" subtitle={undefined}>
            <div>
              <p>{this.state.error?.message ?? this.state.nonReactError?.message}</p>
            </div>
            {/*retryButton*/}
            <Button
              onClick={() => {
                this.resetError();
                window.location.reload();
              }}
            >
              Retry
            </Button>
          </ModalLayout>
        </PageContentContainer>
      );
    }
    return this.props.children;
  }
}
