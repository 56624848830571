import { configureStore } from "@reduxjs/toolkit";
import entryReducer from "./entrySlice"; // Import your entry reducer
import userReducer from "./userSlice"; // Import your user reducer
import cloudReducer from "./cloudSlice"; // Import your loading reducer
import entriesReducer from "./entriesSlice";
import uIReducer from "./uiSlice";
import aiDataReducer from "./aiDataSlice";

const store = configureStore({
  reducer: {
    entry: entryReducer,
    entries: entriesReducer,
    user: userReducer,
    ui: uIReducer,
    cloud: cloudReducer,
    aiData: aiDataReducer,
    // Add more reducers as needed
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        //Ignore this to avoid complaints about non-seriazable data for the user Date type values
        ignoredActions: ["user/setUserData"],
        ignoredPaths: ["user.userData"],
      },
    });
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
